import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer>
        <div id="copyright">&#169; Eda Deniz. All rights reserved.</div>
      </footer>
    );
  }
}

export default Footer;
